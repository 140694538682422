import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FaImage, FaAlignLeft } from 'react-icons/fa';
import { handleSubmit } from '../logic/photoutilss';

function PhotoForm({ onPhotoAdded }) {
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleClose = () => setShowAlert(false);

    return (
        <div className="mt-5 flex justify-center">
            <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
                {showAlert && (
                    <div className={`p-4 mb-4 text-sm ${message.includes('successo') ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`} role="alert">
                        {message}
                        <button type="button" className="ml-2 text-sm text-gray-400 hover:text-gray-500" onClick={handleClose}>
                            <span className="sr-only">Close</span>
                            &times;
                        </button>
                    </div>
                )}
                <form onSubmit={(e) => handleSubmit(e, description, image, setMessage, setShowAlert, onPhotoAdded)}>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                            <FaAlignLeft className="inline-block mr-2" /> Descrizione
                        </label>
                        <textarea
                            rows={3}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                            <FaImage className="inline-block mr-2" /> Immagine
                        </label>
                        <input
                            type="file"
                            onChange={(e) => setImage(e.target.files[0])}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    >
                        Salva
                    </button>
                </form>
            </div>

            <Transition show={showAlert} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                    Conferma
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p>{message}</p>
                                </div>
                                <div className="mt-4">
                                    <button
                                        className="bg-gray-500 hover:bg-gray-600 text-white rounded px-4 py-2 mr-2"
                                        onClick={handleClose}
                                    >
                                        Chiudi
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}

export default PhotoForm;
