import React, { useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

const SearchNews = ({ setSearchTerm }) => {
  return (
    <div className="flex flex-col items-center justify-start py-4 px-4 bg-gray-100">
      <div className="w-full max-w-md space-y-2">
        <h2 className="text-center text-3xl font-extrabold text-gray-900 my-8">LE NOTIZIE</h2>
        <div className="relative mt-2">
          <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
          <input
            type="text"
            className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-full leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Cerca una notizia"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchNews;





