import React, { useState, useEffect } from "react";
import ReviewCard from "./ReviewCard";
import CardForm from "./CardForm";
import AccessDataTable from "./AccessDataTable";
import PhotoForm from "./PhotoForm";
import StatisticsPanel from "./StatisticsPanel";
import AddNewsForm from "./AddNewsForms";
import { useNavigate } from 'react-router-dom';
import OrdiniEffettuati from "./OrdiniEffettuati";
import { fetchStatisticsData, checkCurrentUser } from '../logic/dashboardutils';
import NewsForm from "./NewsForm";
import VideoForm from "./VideoForm";
import { FaPlus } from 'react-icons/fa';

function DashboardAdminLayout() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [statisticsData, setStatisticsData] = useState([]);

    useEffect(() => {
        checkCurrentUser(navigate);
    }, [navigate]);

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setSelectedOption('');
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const renderForm = () => {
        if (selectedOption === 'NGLPop') {
            return <CardForm />;
        } else if (selectedOption === 'Archivio Fotografico') {
            return <PhotoForm onPhotoAdded={() => {}} />;
        } else if (selectedOption === 'News di Cila') {
            return <AddNewsForm />;
        } else if (selectedOption === 'News prima pagina') {
            return <NewsForm />;
        } else if (selectedOption === 'Video') {
            return <VideoForm />;
        }
        return null;
    };

    return (
        <div className="bg-white min-h-screen">
            <div className="container mx-auto px-4">
                <div className="flex justify-between items-center my-4">
                    <h1 className="text-2xl font-bold">Dashboard Amministrazione sito web</h1>
                    <button
                        onClick={handleShow}
                        className="bg-blue-500 hover:bg-blue-600 text-white rounded-full w-12 h-12 flex items-center justify-center"
                    >
                        <FaPlus />
                    </button>
                </div>
                <StatisticsPanel data={statisticsData} />

                {show && (
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>

                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Cosa vuoi creare?
                                </h3>
                                <div className="mt-2">
                                    <button className="block w-full text-left px-4 py-2 text-gray-700" onClick={() => handleOptionChange('NGLPop')}>NGLPop</button>
                                    <button className="block w-full text-left px-4 py-2 text-gray-700" onClick={() => handleOptionChange('Archivio Fotografico')}>Archivio Fotografico</button>
                                    <button className="block w-full text-left px-4 py-2 text-gray-700" onClick={() => handleOptionChange('News di Cila')}>News di Cila</button>
                                    <button className="block w-full text-left px-4 py-2 text-gray-700" onClick={() => handleOptionChange('Video')}>Video</button>
                                    
                                    {renderForm()}
                                </div>
                                <div className="mt-4">
                                    <button className="bg-gray-500 hover:bg-gray-600 text-white rounded px-4 py-2" onClick={handleClose}>
                                        Chiudi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <h1 className="text-2xl font-bold my-4">Recensioni sito web</h1>
                <ReviewCard />
                <OrdiniEffettuati />
                <h1 className="text-2xl font-bold my-4">Gestione accessi sito web</h1>
                <AccessDataTable />
            </div>
        </div>
    );
}

export default DashboardAdminLayout;



