// ../logic/RegisterLogic.js
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from './firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, set } from 'firebase/database';

const useRegisterLogic = () => {
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    const { username, email, password, confirmPassword } = e.target.elements;

    if (password.value !== confirmPassword.value) {
      alert("Le password non coincidono");
      return;
    }

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
      const user = userCredential.user;

      // Store user details in database
      const registrationDate = new Date().toISOString();
      const userAgent = navigator.userAgent;
      const ip = await fetch('https://api.ipify.org?format=json').then(res => res.json()).then(data => data.ip);

      await set(ref(db, 'users/' + user.uid), {
        username: username.value,
        uid: user.uid,
        email: email.value,
        ip,
        registrationDate,
        userAgent,
      });

      alert("Registrazione completata con successo!");
      navigate('/');
    } catch (error) {
      console.error("Errore durante la registrazione: ", error);
      alert("Errore durante la registrazione. Riprova.");
    }
  };

  return { handleRegister };
};

export default useRegisterLogic;










