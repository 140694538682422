import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col items-center p-4 bg-gray-100 min-h-screen">
      <div className="w-full max-w-3xl mt-5 bg-white shadow-md rounded-lg">
        <div className="bg-blue-500 text-white p-4 rounded-t-lg">
          <h2 className="text-xl font-bold">Politica sulla Privacy - NGL Cila</h2>
        </div>
        <div className="p-4">
          <h3 className="text-lg font-semibold">Introduzione</h3>
          <p>
            Su NGL Cila, prendiamo molto sul serio la tua privacy. Questa politica descrive come raccogliamo, utilizziamo e proteggiamo i tuoi dati personali in conformità con il GDPR.
          </p>
          <h3 className="text-lg font-semibold mt-4">Raccolta dei Dati</h3>
          <p>
            Raccogliamo i dati personali che ci fornisci direttamente attraverso il nostro sito web. Questo include informazioni come il tuo nome, indirizzo email e qualsiasi altro dettaglio che scegli di condividere.
          </p>
          <h3 className="text-lg font-semibold mt-4">Conservazione dei Dati</h3>
          <p>
            Tutti i dati personali sono conservati in modo sicuro sui nostri server. Garantiamo che i nostri server siano protetti con le ultime misure di sicurezza per prevenire accessi non autorizzati.
          </p>
          <h3 className="text-lg font-semibold mt-4">Utilizzo dei Dati</h3>
          <p>
            I tuoi dati personali vengono utilizzati esclusivamente per fornire e migliorare i nostri servizi. Non condividiamo i tuoi dati con terze parti senza il tuo esplicito consenso.
          </p>
          <h3 className="text-lg font-semibold mt-4">Protezione dei Dati</h3>
          <p>
            Siamo impegnati a proteggere i tuoi dati personali. Le nostre misure di sicurezza includono crittografia, firewall e audit di sicurezza regolari per garantire che i tuoi dati rimangano al sicuro.
          </p>
          <h3 className="text-lg font-semibold mt-4">I Tuoi Diritti</h3>
          <p>
            In base al GDPR, hai il diritto di accedere, rettificare o cancellare i tuoi dati personali. Se hai domande o richieste riguardanti i tuoi dati, ti preghiamo di contattarci.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
