import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { FaCircle, FaUser, FaInstagram, FaTshirt, FaShoppingCart, FaCreditCard } from "react-icons/fa";
import maglia from '../../assets/img/maglia.jpg';
import useAcquistomaglietteLogic from "../logic/acquistomagliettelogic";
import { Listbox } from '@headlessui/react';

const MaglietteLayout = () => {
    const { formData, handleChange, handleSubmit, savePurchaseToParse, colorOptions } = useAcquistomaglietteLogic();

    return (
        <PayPalScriptProvider options={{ "client-id": "ARBNBvCyXJPQklsTQFrQPGGZE8HNH42o4STLFZl3gkH-c_e3WP2W2SCLcBd_H4swfyeQYTBAxY9sY30t", currency: "EUR" }}>
            <div className="font-inter bg-gray-100 min-h-screen flex items-center justify-center">
                <div className="bg-white shadow-md rounded-lg overflow-hidden w-full max-w-4xl mx-auto">
                    <div className="p-6">
                        <h1 className="text-3xl font-extrabold leading-6 text-gray-900 mb-6 text-center">Acquista la maglia di NGL Cila</h1>
                        <img src={maglia} alt="Maglia" className="w-full object-cover rounded-md mb-6" />
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                                <label htmlFor="nome" className="block text-sm font-medium text-gray-700">
                                    <FaUser className="mr-2 inline-block" /> Nome
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="nome"
                                        id="nome"
                                        value={formData.nome}
                                        onChange={handleChange}
                                        className="block w-full shadow-sm sm:text-sm border-black rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        placeholder="Inserisci il tuo nome"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="nomeig" className="block text-sm font-medium text-gray-700">
                                    <FaInstagram className="mr-2 inline-block" /> Nome Instagram
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="nomeig"
                                        id="nomeig"
                                        value={formData.nomeig}
                                        onChange={handleChange}
                                        className="block w-full shadow-sm sm:text-sm border-black rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        placeholder="Inserisci il tuo nome instagram"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="taglia" className="block text-sm font-medium text-gray-700">
                                    <FaTshirt className="mr-2 inline-block" /> Taglia
                                </label>
                                <div className="mt-1">
                                    <select
                                        name="taglia"
                                        id="taglia"
                                        value={formData.taglia}
                                        onChange={handleChange}
                                        className="block w-full shadow-sm sm:text-sm border-black rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    >
                                        <option value="XS">XS</option>
                                        <option value="S">S</option>
                                        <option value="M">M</option>
                                        <option value="L">L</option>
                                        <option value="XL">XL</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="colore" className="block text-sm font-medium text-gray-700">
                                    <FaCircle className="mr-2 inline-block" /> Colore
                                </label>
                                <div className="mt-1">
                                    <Listbox value={formData.colore} onChange={(value) => handleChange({ target: { name: 'colore', value } })}>
                                        <div className="relative">
                                            <Listbox.Button className="relative w-full cursor-default rounded-md border border-black bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm">
                                                <span className="block truncate">{formData.colore}</span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                                                        <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" />
                                                    </svg>
                                                </span>
                                            </Listbox.Button>
                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {colorOptions.map((option) => (
                                                    <Listbox.Option
                                                        key={option.value}
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'text-white bg-indigo-600' : 'text-gray-900'
                                                            }`
                                                        }
                                                        value={option.value}
                                                    >
                                                        {({ selected }) => (
                                                            <>
                                                                <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                                    {option.label}
                                                                </span>
                                                                {selected ? (
                                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                                        <FaCircle className="h-5 w-5" aria-hidden="true" style={{ color: option.color }} />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </div>
                                    </Listbox>
                                    <div className="mt-2 flex items-center">
                                        <FaCircle className="h-5 w-5 mr-2" style={{ color: colorOptions.find(option => option.value === formData.colore).color }} />
                                        <span className="text-sm">{formData.colore}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="quantita" className="block text-sm font-medium text-gray-700">
                                    <FaShoppingCart className="mr-2 inline-block" /> Quantità
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="number"
                                        name="quantita"
                                        id="quantita"
                                        value={formData.quantita}
                                        onChange={handleChange}
                                        className="block w-full shadow-sm sm:text-sm border-black rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        placeholder="Inserisci la quantità"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="pagamento" className="block text-sm font-medium text-gray-700">
                                    <FaCreditCard className="mr-2 inline-block" /> Metodo di Pagamento
                                </label>
                                <div className="mt-1">
                                    <select
                                        name="pagamento"
                                        id="pagamento"
                                        value={formData.pagamento}
                                        onChange={handleChange}
                                        className="block w-full shadow-sm sm:text-sm border-black rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    >
                                        <option value="online">Online</option>
                                        <option value="fisico">Fisico</option>
                                    </select>
                                </div>
                            </div>

                            {formData.pagamento === 'fisico' && (
                                <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-6">
                                    Ordina
                                </button>
                            )}
                        </form>
                        {formData.pagamento === 'online' && (
                            <div className="flex justify-center mt-6">
                                <PayPalButtons
                                    createOrder={(data, actions) => {
                                        return actions.order.create({
                                            purchase_units: [{
                                                amount: {
                                                    value: '9.99' // Costo della maglietta
                                                }
                                            }]
                                        });
                                    }}
                                    onApprove={(data, actions) => {
                                        return actions.order.capture().then(async (details) => {
                                            await savePurchaseToParse(details);
                                        });
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </PayPalScriptProvider>
    );
};

export default MaglietteLayout;



