import React from 'react';
import { FaTrain } from 'react-icons/fa';

const RFIButton = () => {
  const handleClick = () => {
    window.location.href = 'https://www.rfi.it/it/stazioni/pagine-stazioni/servizi-di-qualita/informazioni-al-pubblico/monitor-arrivi-partenze-live.html';
  };

  return (
    <button
      onClick={handleClick}
      className="flex items-center justify-center px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300"
    >
      <FaTrain className="mr-2" />
      Collegati ai monitor di RFI
    </button>
  );
};

export default RFIButton;
