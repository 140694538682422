import Parse from 'parse';

export const createDefaultUser = async () => {
  if (!Parse.applicationId) {
    console.error('Parse non è stato inizializzato correttamente.');
    return;
  }

  const query = new Parse.Query(Parse.User);
  query.equalTo('username', 'Nichodev');
  const user = await query.first();

  if (!user) {
    const newUser = new Parse.User();
    newUser.set('username', 'Nichodev');
    newUser.set('password', 'Nglcila1001');
    try {
      await newUser.signUp();
      console.log('Default user created');
    } catch (error) {
      console.error('Error creating default user: ', error);
    }
  }
};

export const handleLogin = async (e, username, password, setError, navigate) => {
  e.preventDefault();
  setError('');

  if (!Parse.applicationId) {
    setError('Parse non è stato inizializzato correttamente.');
    return;
  }

  try {
    const user = await Parse.User.logIn(username, password);
    if (user) {
      navigate('/dashboard-admin');
    }
  } catch (err) {
    setError('Accesso negato: credenziali non valide.');
  }
};

