import React, { useState } from 'react';
import { FaImage, FaVideo, FaHeading, FaAlignLeft } from 'react-icons/fa';
import { handleSubmit } from '../logic/logicanewform';

function AddNewsForm() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [mediaType, setMediaType] = useState('image');
  const [media, setMedia] = useState(null);

  return (
    <div className="container mx-auto p-4">
      <form onSubmit={(e) => handleSubmit(e, title, content, mediaType, media)} className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-md">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            <FaHeading className="inline-block mr-2" /> Titolo
          </label>
          <input
            type="text"
            placeholder="Inserisci il titolo"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            <FaAlignLeft className="inline-block mr-2" /> Contenuto
          </label>
          <textarea
            rows={3}
            placeholder="Inserisci il contenuto"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Tipo di Media</label>
          <select
            value={mediaType}
            onChange={(e) => setMediaType(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            required
          >
            <option value="image"><FaImage className="inline-block mr-2" /> Immagine</option>
            <option value="video"><FaVideo className="inline-block mr-2" /> Video</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Media</label>
          <input
            type="file"
            onChange={(e) => setMedia(e.target.files[0])}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            required
          />
        </div>

        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Aggiungi Notizia
        </button>
      </form>
    </div>
  );
}

export default AddNewsForm;
