import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, ArcElement, BarElement, Tooltip, Legend } from 'chart.js';

// Registrazione delle scale e degli elementi
Chart.register(CategoryScale, LinearScale, ArcElement, BarElement, Tooltip, Legend);

const StatisticsPanel = ({ data }) => {
    const barData = {
        labels: data.map(item => item.month),
        datasets: [
            {
                label: 'Visite Mensili',
                data: data.map(item => item.visits),
                backgroundColor: 'rgba(75,192,192,0.6)',
            },
        ],
    };

    const pieData = {
        labels: ['Desktop', 'Mobile', 'Tablet'],
        datasets: [
            {
                data: ['Desktop', 'Mobile', 'Tablet'].map(device => 
                    data.filter(item => item.device === device).reduce((acc, item) => acc + item.count, 0)
                ),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
        ],
    };

    return (
        <div className="mt-5 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Visite Mensili</h3>
                    <div className="mt-2 bg-white p-4 rounded-lg shadow-md">
                        <Bar data={barData} />
                    </div>
                </div>
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Dispositivi Utilizzati</h3>
                    <div className="mt-2 bg-white p-4 rounded-lg shadow-md">
                        <Pie data={pieData} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatisticsPanel;
