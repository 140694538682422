import React from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import AccountDashboardLayout from "../components/layout/AccountDashboardLayout";

function AccountDashboard() {
    return <div>
        <Navbar />
        <AccountDashboardLayout />
        <Footer />
    </div>;
}

export default AccountDashboard;