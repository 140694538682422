import React, { useState, useEffect } from 'react';
import Parse from 'parse';
import { Switch } from '@headlessui/react';
import '../../assets/css/shop.css'; // Assicurati di creare e importare questo file CSS
import '../../assets/css/faq.css'; // Importa il file CSS per la sezione FAQ

const Shopss = () => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [faqOpen, setFaqOpen] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      const ShopCard = Parse.Object.extend('ShopCard');
      const query = new Parse.Query(ShopCard);
      const results = await query.find();
      const fetchedProducts = results.map(product => ({
        title: product.get('title'),
        text: product.get('text'),
        imageUrl: product.get('imageUrl'),
        price: product.get('price'),
        sellerName: product.get('sellerName'),
        paymentLink: product.get('paymentLink'),
        reviews: product.get('reviews') // Assicurati di avere questo campo nel tuo database
      }));
      setProducts(fetchedProducts);
    };

    fetchProducts();
  }, []);

  const handleShowModal = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const toggleFaq = (index) => {
    setFaqOpen(prevFaqOpen => ({
      ...prevFaqOpen,
      [index]: !prevFaqOpen[index]
    }));
  };

  return (
    <div className={darkMode ? 'shop-container dark-mode' : 'shop-container'}>
      {showSidebar && (
        <div className="sidebar">
          <div className="sidebar-header">
            <h3>Categorie</h3>
            <Switch
              checked={darkMode}
              onChange={setDarkMode}
              className={`${darkMode ? 'bg-gray-700' : 'bg-gray-300'} relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200`}
            >
              <span
                className={`${darkMode ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform bg-white rounded-full transition-transform duration-200`}
              />
            </Switch>
          </div>
          <ul>
            <li><a href="#categoria1">Categoria 1</a></li>
            <li><a href="#categoria2">Categoria 2</a></li>
            <li><a href="#categoria3">Categoria 3</a></li>
          </ul>
        </div>
      )}
      <div className="main-content">
        <div className="shop-header">
          <h1 style={{ color: darkMode ? 'white' : 'black', fontWeight: 'bold' }}>Lo store online di NGL Cila</h1>
          <p>Venduto da noi e da altri utenti, con cura e passione</p>
          <input type="text" placeholder="Cerca prodotti..." className="shop-search" />
          <button onClick={toggleSidebar} className="sidebar-toggle">
            {showSidebar ? 'Nascondi Sidebar' : 'Mostra Sidebar'}
          </button>
        </div>

        <div className="shop-products">
          {products.map((product, index) => (
            <div
              key={index}
              className="shop-product"
              onClick={() => handleShowModal(product)}
            >
              <img src={product.imageUrl} alt={product.title} className="product-image" />
              <div className="product-info">
                <h2>{product.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: product.text }}></p>
                <p className="product-price">{product.price}</p>
                <p className="product-seller"><em>Venduto da: {product.sellerName}</em></p>
                <div className="product-reviews">
                  {product.reviews && product.reviews.map((review, i) => (
                    <p key={i}><strong>{review.user}:</strong> {review.comment}</p>
                  ))}
                </div>
                <button className="add-to-cart">Aggiungi al carrello</button>
              </div>
            </div>
          ))}
        </div>

        {selectedProduct && (
          <div className="modal" onClick={handleCloseModal}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
              <button className="close-button" onClick={handleCloseModal}>X</button>
              <img src={selectedProduct.imageUrl} alt={selectedProduct.title} className="modal-image" />
              <h2>{selectedProduct.title}</h2>
              <p dangerouslySetInnerHTML={{ __html: selectedProduct.text }}></p>
              <p className="product-price">{selectedProduct.price}</p>
              <p className="product-seller"><em>Venduto da: {selectedProduct.sellerName}</em></p>
              {selectedProduct.paymentLink && (
                <a href={selectedProduct.paymentLink} target="_blank" rel="noopener noreferrer">Clicca per ordinare</a>
              )}
            </div>
          </div>
        )}

        {/* Sezione FAQ */}
        <div className="faq-section">
          <h2>Domande Frequenti</h2>
          {[
            {
              question: "Come posso effettuare un ordine?",
              answer: "Per effettuare un ordine, seleziona il prodotto desiderato, aggiungilo al carrello e procedi al pagamento tramite il link fornito."
            },
            {
              question: "Quali metodi di pagamento accettate?",
              answer: "Accettiamo pagamenti tramite carta di credito, PayPal e bonifico bancario."
            },
            {
              question: "Come posso contattare il venditore?",
              answer: "Puoi contattare il venditore tramite il link fornito nella pagina del prodotto o inviare un messaggio tramite il nostro sistema di messaggistica."
            },
            {
              question: "Qual è la politica di reso?",
              answer: "Accettiamo resi entro 30 giorni dall'acquisto, purché il prodotto sia nelle stesse condizioni in cui è stato ricevuto."
            }
          ].map((faq, index) => (
            <div key={index} className="faq-item">
              <button className="faq-question" onClick={() => toggleFaq(index)} style={{ color: darkMode ? 'white' : 'black' }}>
                {faq.question}
              </button>
              {faqOpen[index] && (
                <div className="faq-answer">
                  <p style={{ color: darkMode ? 'white' : 'black' }}>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Shopss;











