import { ref, get, set, query, orderByChild, equalTo, push, remove } from "firebase/database";
import { db } from "./firebase"; // Importazione corretta di `db`

// Fetch Access Data
export const fetchAccessData = async (setAccessData) => {
  const accessDataRef = ref(db, "AccessData");
  try {
    const snapshot = await get(accessDataRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      const results = await Promise.all(Object.keys(data).map(async (key) => {
        const userData = await getUserData(data[key].ipAddress);
        return {
          ...data[key],
          dateTime: new Date(data[key].dateTime).toLocaleString(), // Convertire il timestamp in una data leggibile
          username: userData.username || 'Non registrato',
          uid: userData.uid || 'Non registrato'
        };
      }));
      setAccessData(results);
    } else {
      setAccessData([]);
    }
  } catch (error) {
    console.error('Errore durante il recupero dei dati di accesso:', error);
  }
};

const getUserData = async (ipAddress) => {
  const userRef = query(ref(db, 'users'), orderByChild('ip'), equalTo(ipAddress));
  const userSnapshot = await get(userRef);
  if (userSnapshot.exists()) {
    const userData = userSnapshot.val();
    const uid = Object.keys(userData)[0];
    return { uid, username: userData[uid].username };
  }
  return { uid: 'Non registrato', username: 'Non registrato' };
};

// Fetch Banned IPs
export const fetchBannedIPs = async (setBannedIPs) => {
  const bannedIPsRef = ref(db, "BannedIPs");
  try {
    const snapshot = await get(bannedIPsRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      const results = Object.keys(data).map((key) => data[key].ipAddress);
      setBannedIPs(results);
    } else {
      setBannedIPs([]);
    }
  } catch (error) {
    console.error('Errore durante il recupero degli IP bannati:', error);
  }
};

// Handle Confirm Ban
export const handleConfirmBan = async (
  currentIP,
  banReason,
  banDuration,
  banExpiryDate,
  setBannedIPs,
  bannedIPs,
  setShowModal,
  setBanReason,
  setBanDuration,
  setBanExpiryDate
) => {
  const bannedIPsRef = ref(db, "BannedIPs");
  const newBanRef = push(bannedIPsRef);
  const bannedIP = {
    ipAddress: currentIP,
    reason: banReason,
    expiryDate: banDuration === 'temporary' ? banExpiryDate : null,
  };
  try {
    await set(newBanRef, bannedIP);
    setBannedIPs([...bannedIPs, currentIP]);
    setShowModal(false);
    setBanReason('');
    setBanDuration('permanent');
    setBanExpiryDate(null);
  } catch (error) {
    console.error('Errore durante il ban dell\'IP:', error);
  }
};

// Handle Unban
export const handleUnban = async (ip, setBannedIPs, bannedIPs) => {
  const bannedIPsRef = ref(db, "BannedIPs");
  const q = query(bannedIPsRef, orderByChild('ipAddress'), equalTo(ip));
  try {
    const snapshot = await get(q);
    if (snapshot.exists()) {
      const data = snapshot.val();
      const key = Object.keys(data)[0]; // Trova la chiave dell'IP specifico
      const banRef = ref(db, `BannedIPs/${key}`);
      await remove(banRef); // Rimuovi l'IP dal database
      setBannedIPs(bannedIPs.filter((bannedIP) => bannedIP !== ip)); // Aggiorna lo stato dei IP bannati
    }
  } catch (error) {
    console.error('Errore durante lo sbannamento dell\'IP:', error);
  }
};






