import React, { useState, useEffect, useCallback } from 'react';
import { ref, get, set, query, push, orderByChild, equalTo } from "firebase/database";
import { db } from "../logic/firebase";
import '../../assets/css/banbackground.css'; // Assicurati di creare questo file CSS

const UserAccessData = ({ onAccessDataSaved }) => {
  const [ipAddress, setIpAddress] = useState('');
  const [browserInfo] = useState({
    browserName: navigator.appName,
    browserVersion: navigator.appVersion,
    os: navigator.platform,
    language: navigator.language
  });
  const [additionalData] = useState({
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    userAgent: navigator.userAgent
  });
  const [showBanModal, setShowBanModal] = useState(false);
  const [banMessage, setBanMessage] = useState('');
  const [isBanned, setIsBanned] = useState(false);

  const fetchIpAddress = useCallback(async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      console.log('Indirizzo IP ottenuto:', data.ip);
      setIpAddress(data.ip);
    } catch (error) {
      console.error('Errore durante il recupero dell\'indirizzo IP:', error);
    }
  }, []);

  const saveAccessData = useCallback(async () => {
    const accessData = {
      dateTime: new Date().toISOString(),
      ipAddress,
      browserInfo,
      additionalData
    };

    try {
      const newAccessDataRef = push(ref(db, 'AccessData'));
      await set(newAccessDataRef, accessData);
      console.log('Dati di accesso salvati con successo');
      if (onAccessDataSaved) {
        onAccessDataSaved();
      }
    } catch (error) {
      console.error('Errore durante il salvataggio dei dati:', error);
    }
  }, [ipAddress, browserInfo, additionalData, onAccessDataSaved]);

  const checkBannedIP = useCallback(async (ip) => {
    const bannedIPsRef = query(ref(db, 'BannedIPs'), orderByChild('ipAddress'), equalTo(ip));
    try {
      const snapshot = await get(bannedIPsRef);
      if (snapshot.exists()) {
        const data = Object.values(snapshot.val())[0];
        const reason = data.reason;
        const expiryDate = data.expiryDate ? new Date(data.expiryDate) : null;
        const now = new Date();
        console.log('Dati del ban ottenuti:', { data, now });
        if (expiryDate && expiryDate < now) {
          await saveAccessData(); // IP non più bannato, salva i dati di accesso
        } else {
          const expiryMessage = expiryDate ? `Il ban scade il: ${expiryDate.toLocaleDateString()}` : 'Ban permanente';
          setBanMessage(`
            <h1 style="color: black; font-weight: bold;">Sei stato bannato</h1>
            <p style="color: black">Abbiamo sospeso il tuo account per violazione delle linee guida della community.</p>
            <p style="color: black">Motivazione: ${reason}</p>
            <p style="color: black">${expiryMessage}</p>
          `);
          setShowBanModal(true);
          setIsBanned(true);
        }
      } else {
        await saveAccessData(); // IP non bannato, salva i dati di accesso
      }
    } catch (error) {
      console.error('Errore durante la verifica dell\'IP bannato:', error);
    }
  }, [saveAccessData]);

  useEffect(() => {
    fetchIpAddress();
  }, [fetchIpAddress]);

  useEffect(() => {
    if (ipAddress) {
      checkBannedIP(ipAddress);
    }
  }, [ipAddress, checkBannedIP]);

  useEffect(() => {
    if (isBanned) {
      document.body.style.overflow = 'hidden';
      document.body.style.backgroundColor = 'black'; // Cambia lo sfondo in nero
      document.body.style.color = 'black'; // Cambia il colore del testo in nero
      // Trova tutte le immagini e sostituiscile con spinner
      document.querySelectorAll('img').forEach(img => {
        const spinner = document.createElement('div');
        spinner.className = 'spinner-border';
        spinner.setAttribute('role', 'status');
        const span = document.createElement('span');
        span.className = 'visually-hidden';
        span.textContent = 'Loading...';
        spinner.appendChild(span);
        img.replaceWith(spinner);
      });
      // Cambia il colore dei bordi e dei pulsanti in nero
      document.querySelectorAll('button, input, select, textarea, p, h1, h2, h3, h4, h5, h6, a, Card.Title').forEach(element => {
        element.style.color = 'black';
        element.style.borderColor = 'black';
      });
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.backgroundColor = 'white'; // Resetta lo sfondo
      document.body.style.color = 'initial'; // Resetta il colore del testo
    }
  }, [isBanned]);

  return (
    <div>
      {showBanModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                ⚠️Accesso Negato⚠️
              </h3>
              <div className="mt-2">
                <div dangerouslySetInnerHTML={{ __html: banMessage }} />
              </div>
              <div className="mt-4">
                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserAccessData;
