import React, { useState } from 'react';
import { useCombobox } from 'downshift';
import axios from 'axios';
import stations from '../../assets/olther/stations.json';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { FaSearch } from 'react-icons/fa';

const regionMapping = {
  1: 'Lombardia',
  2: 'Liguria',
  3: 'Piemonte',
  4: "Valle d'Aosta",
  5: 'Lazio',
  6: 'Umbria',
  7: 'Molise',
  8: 'Emilia Romagna',
  10: 'Friuli Venezia Giulia',
  11: 'Marche',
  12: 'Veneto',
  13: 'Toscana',
  14: 'Sicilia',
  15: 'Basilicata',
  16: 'Puglia',
  17: 'Calabria',
  18: 'Campania',
  19: 'Abruzzo',
  20: 'Sardegna',
  22: 'Trentino Alto Adige',
};

const mapContainerStyle = {
  height: "400px",
  width: "100%"
};

const DettagliStazione = () => {
  const [selectedStation, setSelectedStation] = useState(null);
  const [dettagliStazione, setDettagliStazione] = useState(null);
  const [stationsList, setStationsList] = useState(stations.slice(0, 20));
  const [moreStations, setMoreStations] = useState(stations.length > 20);
  const [inputValue, setInputValue] = useState('');

  const loadMoreStations = () => {
    const newLength = stationsList.length + 20;
    setStationsList(stations.slice(0, newLength));
    setMoreStations(newLength < stations.length);
  };

  const filteredStations = (inputValue) => {
    return stations.filter(station =>
      station.long_name.toLowerCase().includes(inputValue.toLowerCase())
    ).slice(0, 20);
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getLabelProps,
    getToggleButtonProps,
    getItemProps,
    highlightedIndex,
    selectedItem,
  } = useCombobox({
    items: filteredStations(inputValue),
    onInputValueChange: ({ inputValue }) => {
      setInputValue(inputValue);
      setStationsList(filteredStations(inputValue));
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        if (selectedItem.code === "load_more") {
          loadMoreStations();
        } else {
          setSelectedStation(selectedItem);
        }
      }
    },
    itemToString: (item) => (item ? item.long_name : ''),
  });

  const defaultOptions = stationsList.map(station => ({
    long_name: station.long_name,
    code: station.code
  }));

  if (moreStations) {
    defaultOptions.push({ long_name: "Mostra altre stazioni...", code: "load_more" });
  }

  const fetchDettagliStazione = async () => {
    if (selectedStation) {
      const { code } = selectedStation;
      const selectedStationObj = stations.find(station => station.code === code);
      if (selectedStationObj) {
        const { region } = selectedStationObj;
        try {
          const response = await axios.get(`https://trenitaliapijs-production.up.railway.app/station-details/${code}/${region}`);
          const data = response.data;
          setDettagliStazione(data);
        } catch (error) {
          console.error('Errore nel recupero dei dettagli della stazione:', error);
        }
      }
    }
  };

  return (
    <div className="container mx-auto mt-5">
      <div {...getToggleButtonProps()}>
        <input
          {...getInputProps()}
          className="zcns-select-input"
          placeholder="Seleziona una stazione..."
        />
      </div>
      <ul {...getMenuProps()} className="zcns-menu">
        {isOpen &&
          defaultOptions.map((item, index) => (
            <li
              key={index}
              {...getItemProps({ item, index })}
              className={`zcns-menu-item ${highlightedIndex === index ? 'zcns-highlighted-item' : ''}`}
            >
              {item.long_name}
            </li>
          ))}
      </ul>
      {selectedStation && (
        <button
          onClick={fetchDettagliStazione}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-2 flex items-center"
        >
          <FaSearch className="mr-2" />
          Cerca
        </button>
      )}
      {dettagliStazione && (
        <div className="mt-5">
          <h3 className="text-xl font-bold mb-2">{dettagliStazione.localita.nomeLungo}</h3>
          <p><strong>Codice Stazione:</strong> {dettagliStazione.codiceStazione}</p>
          <p><strong>Regione:</strong> {regionMapping[dettagliStazione.codReg]}</p>
          <p><strong>Latitudine:</strong> {dettagliStazione.lat}</p>
          <p><strong>Longitudine:</strong> {dettagliStazione.lon}</p>
          <p><strong>Città:</strong> {dettagliStazione.nomeCitta}</p>
          <LoadScript googleMapsApiKey="AIzaSyD3ionmp0aaRDdH14ftN-5I2Q58yhyqcp4">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={{ lat: dettagliStazione.lat, lng: dettagliStazione.lon }}
              zoom={13}
            >
              <Marker position={{ lat: dettagliStazione.lat, lng: dettagliStazione.lon }} />
            </GoogleMap>
          </LoadScript>
        </div>
      )}
    </div>
  );
};

export default DettagliStazione;

