import React, { useEffect } from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import SearchNews from "../components/layout/SearchNews";
import NewsCards from "../components/layout/NewsSections";
import UserAccessData from "../components/logic/userAccessData";


function News() {
    useEffect(() => {
        document.title = 'News'; // Imposta il titolo della pagina
      }, []);
    return(
    <div>
        <Navbar />
        <UserAccessData />
        <NewsCards />
        <Footer />
    </div>
    );
}

export default News;