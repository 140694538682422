import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import Parse from 'parse';

function Popcard() {
  const [cards, setCards] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Recuperare i dati delle card dal database
    const fetchCardData = async () => {
      const CardData = Parse.Object.extend('CardData');
      const query = new Parse.Query(CardData);

      try {
        const results = await query.find();
        setCards(results);
      } catch (error) {
        console.error('Error while fetching card data: ', error);
      }
    };

    fetchCardData();
  }, []);

  const filteredCards = cards.filter(card =>
    card.get('title').toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="py-5 font-sans">
      <div className="container mx-auto">
        <div className="mb-5 relative">
          <input
            type="text"
            placeholder="Cerca un NGLPop!"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 text-black border-2 border-black rounded-lg transition duration-300 ease-in-out transform focus:border-blue-500 focus:shadow-outline pl-10"
            style={{ fontFamily: 'Inter, sans-serif' }}
          />
          <FaSearch className="absolute left-3 top-3 text-black" />
        </div>
        <div className="flex flex-wrap -mx-4">
          {filteredCards.map((card) => (
            <div className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8" key={card.id}>
              <div className="bg-white text-black rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl">
                <img
                  className="w-full h-48 object-cover rounded-t-lg"
                  src={card.get('imageUrl')}
                  alt={card.get('title')}
                />
                <div className="p-4">
                  <h5 className="text-xl font-bold mb-2" style={{ fontFamily: 'Inter, sans-serif' }}>{card.get('title')}</h5>
                  <p className="mb-4" style={{ fontFamily: 'Inter, sans-serif' }}>{card.get('text')}</p>
                  <a
                    href={card.get('buttonLink')}
                    className="inline-block px-4 py-2 bg-blue-500 text-white rounded-full transition duration-300 ease-in-out transform hover:bg-blue-700"
                    style={{ fontFamily: 'Inter, sans-serif' }}
                  >
                    Leggi l'articolo
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Popcard;



