import React, { useEffect } from 'react';
import Footer from '../components/layout/Footer';
import Navbar from '../components/layout/Navbar';
import OrarioAutoguidovieCard from '../components/layout/OrarioAutoguidovieSection';
import UserAccessData from '../components/logic/userAccessData';

function OrarioLineeAutoguidovie() {
     useEffect(() => {
                document.title = 'Orario Linee Autoguidovie'; // Imposta il titolo della pagina
              }, []);
    return (
        <div>
            <Navbar />
            <UserAccessData />
            <OrarioAutoguidovieCard />
            <Footer />
        </div>
    );
}

export default OrarioLineeAutoguidovie;