import React from 'react';

const CardTreni = ({ treni }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
      {treni.map((treno, index) => {
        // Verifica se il treno è regionale e ha 4 cifre nel numero e codiceCliente è 63
        let tipologiaTreno = treno.compTipologiaTreno;
        if (treno.categoria === "REG" && treno.numeroTreno.toString().length === 4) {
          if (treno.codiceCliente === 63) {
            tipologiaTreno = "Regio Express";
          } else {
            tipologiaTreno = "Regionale Veloce";
          }
        } else if (treno.categoria === "REG" && treno.numeroTreno.toString().length === 5) {
          tipologiaTreno = "Regionale";
        } else {
          // Nuova logica per assegnare la tipologia di treno in base alla categoriaDescrizione
          switch (treno.categoriaDescrizione) {
            case "IC":
              tipologiaTreno = "Intercity";
              break;
            case "EC":
              tipologiaTreno = "Eurocity";
              break;
            case "EN":
              tipologiaTreno = "Euronight";
              break;
            case "ICN":
              tipologiaTreno = "Intercity Notte";
              break;
            case "FR":
              tipologiaTreno = "Frecciarossa";
              break;
            case "FB":
              tipologiaTreno = "Frecciabianca";
              break;
            case "FA":
              tipologiaTreno = "Frecciargento";
              break;
            default:
              break;
          }
        }

        return (
          <div key={index} className="bg-white rounded-lg shadow-md p-4">
            <div className="flex items-center mb-4 space-x-2">
              <img src={treno.iconaTreno} alt="Logo Compagnia Ferroviaria" className="w-16 h-auto object-contain" />
              <h3 className="text-xl font-bold">Treno {treno.compNumeroTreno}</h3>
            </div>
            <div className="flex items-center mb-2 space-x-2">
              <img src={treno.tipologiaTrenoIcona} alt="Icona Tipologia Treno" className="w-8 h-auto object-contain" />
              <p><strong>Tipologia:</strong> {tipologiaTreno}</p>
            </div>
            
            <p><strong>Destinazione:</strong> {treno.destinazione}</p>
            <p><strong>Ora Partenza:</strong> {new Date(treno.orarioPartenza).toLocaleTimeString()}</p>
            <p><strong>Numero del Treno:</strong> {treno.numeroTreno}</p>
            <p><strong>Ritardo:</strong> {treno.ritardo} minuti</p>
            <p><strong>Stato Treno:</strong> {treno.statoTreno ? treno.statoTreno : "Informazioni non disponibili"}</p>
            <p><strong>Binario Programmato:</strong> {treno.binarioProgrammato}</p>
            <p><strong>Binario Reale:</strong> {treno.binarioReale}</p>
            <p><strong>Categoria:</strong> {treno.categoriaDescrizione}</p>
            <p><strong>Binario Effettivo Partenza:</strong> {treno.binarioEffettivoPartenzaDescrizione}</p>
            {treno.statoTreno === "CANCELLATO" && <p className="text-red-500">CANCELLATO</p>}
          </div>
        );
      })}
    </div>
  );
};

export default CardTreni;





