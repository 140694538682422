import React from 'react';
import useLoginLogic from '../logic/LoginLogicAuth';
import ngllogo from '../../assets/img/ngllogo.jpg';

const LoginPageLayout = () => {
  const { handleGoogleSignIn, handleEmailPasswordSignIn, error } = useLoginLogic();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600 animate-fade-in">
      <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-lg">
        <div className="flex justify-center">
          <img src={ngllogo} alt="Logo" className="w-16 h-16 rounded-full shadow-lg animate-fade-in" />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 animate-slide-down">Benvenuto!</h2>
        <p className="mt-2 text-center text-sm text-gray-600 animate-fade-in">Accedi al tuo account</p>
        <form className="mt-8 space-y-6" onSubmit={handleEmailPasswordSignIn}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">Email</label>
              <input
                id="email-address"
                name="email"
                type="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Email"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-sm">
              <a href="/reset-password" className="font-medium text-blue-600 hover:text-blue-500 animate-fade-in">
                Password dimenticata?
              </a>
            </div>
            {error && (
              <div className="text-sm text-red-600 mt-2">
                {error}
              </div>
            )}
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Accedi
            </button>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={handleGoogleSignIn}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Accedi con Google
            </button>
          </div>
          <div className="text-center mt-6">
            <a href="/register" className="font-medium text-blue-600 hover:text-blue-500 animate-fade-in">
              Non hai un account? Registrati
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPageLayout;










