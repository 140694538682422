import React from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import RegisterPageLayout from "../components/layout/RegisterPageLayout";

function RegisterPage() {
    return <div>
             <Navbar />
             <RegisterPageLayout />
             <Footer />
           </div>;
}

export default RegisterPage;