import React, { useEffect } from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import Shopss from "../components/layout/StoreLayout";
import UserAccessData from "../components/logic/userAccessData";


function Shop () {
     useEffect(() => {
                document.title = 'Store'; // Imposta il titolo della pagina
              }, []);
    return (
        <div>
            <Navbar />
            <UserAccessData />
            <Shopss />
            <Footer />
        </div>
    )
}

export default Shop;