import React, { useEffect, useState } from 'react';
import Parse from 'parse';

const VideoLayout = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchVideos = async () => {
      const VideoData = Parse.Object.extend('VideoData');
      const query = new Parse.Query(VideoData);
      query.descending('createdAt');
      try {
        const results = await query.find();
        const videoList = results.map((video) => ({
          id: video.id,
          url: video.get('videoUrl'),
          description: video.get('description'),
        }));
        setVideos(videoList);
      } catch (error) {
        console.error('Error while fetching video data: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-center text-3xl font-extrabold text-gray-900 mb-8">
        Video di NGL Cila
      </h1>
     
      <div className="flex flex-col gap-6 items-center">
        {loading ? (
          <div className="flex justify-center items-center min-h-screen">
            <svg className="animate-spin h-8 w-8 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
            </svg>
          </div>
        ) : (
          videos.map((video) => (
            <div key={video.id} className="w-full max-w-3xl">
              <video
                controls
                src={video.url}
                className="w-full h-auto rounded-md shadow-lg"
              />
              <p className="mt-2 text-lg text-gray-700 text-left">
                {video.description}
              </p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default VideoLayout;
