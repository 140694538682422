import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import { auth, provider } from './firebase';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';

const useLoginLogic = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Inizializza useNavigate

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
      navigate('/home'); // Reindirizza a /home dopo il login con Google
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEmailPasswordSignIn = async (event) => {
    event.preventDefault(); // Previene il comportamento di default del form
    const email = event.target.email.value;
    const password = event.target.password.value;
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home'); // Reindirizza a /home dopo il login con Email/Password
    } catch (err) {
      if (err.code === 'auth/invalid-credential') {
        setError('Password non corretta'); // Messaggio di errore personalizzato
      } else {
        setError(err.message);
      }
    }
  };

  return {
    handleGoogleSignIn,
    handleEmailPasswordSignIn,
    error,
  };
};

export default useLoginLogic;











