import React from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import LoginAdminLayout from "../components/layout/LoginAdminLayout";


function LoginAdmin() {
    return (
        <div>
            <Navbar />
            <LoginAdminLayout />
            <Footer />
        </div>
    );
}

export default LoginAdmin;