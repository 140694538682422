import React, {useEffect} from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import VideoLayout from "../components/layout/VideoLayout";
import UserAccessData from "../components/logic/userAccessData";


function Video () {
    useEffect(() => {
            document.title = 'Video'; // Imposta il titolo della pagina
          }, []);
    return(
        <div>
            <Navbar />
            <UserAccessData />
            <VideoLayout />
            <Footer />
        </div>
    )
};

export default Video;