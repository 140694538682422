// Installare le librerie necessarie
// npm install react-spring react-icons react-confetti react-use

import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import '../../assets/css/instagramClassifica.css';

const data = [
  { name: '@saralabro57', rank: 1 },
  { name: '@sophie_laballerina', rank: 2 },
  { name: '@la_nana_del_2011 e @_lollo_.0', rank: 3 },
  // Aggiungi altri ragazzi qui
];

function App() {
  const [showWinners, setShowWinners] = useState(false);
  const [winner, setWinner] = useState(null);
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (showWinners) {
      const timer = setTimeout(() => {
        setWinner(data.find(person => person.rank === 1));
      }, 3000); // 3 secondi dopo aver mostrato tutti i podi
      return () => clearTimeout(timer);
    }
  }, [showWinners]);

  return (
    <div className="zr_app-container">
      <header className="zr_app-header">
        <h1>I ragazzi/le ragazze più belle</h1>
        <p>dopo aver cliccato il pulsante clicca di nuovo sulla schermata grigia</p>
        {!showWinners ? (
          <div className="zr_click-to-show" onClick={() => setShowWinners(true)}>
            Clicca qui per mostrare i vincitori
          </div>
        ) : (
          <div className="zr_podium">
            {data.slice().reverse().map((item, index) => (
              <AnimatedCard 
                key={index} 
                name={item.name} 
                rank={item.rank} 
              />
            ))}
          </div>
        )}
        {winner && <Confetti width={width} height={height} />}
      </header>
    </div>
  );
}

function AnimatedCard({ name, rank }) {
  const props = useSpring({
    to: { opacity: 1, transform: rank === 1 ? 'translateY(0) scale(1.2)' : 'translateY(0)' },
    from: { opacity: 0, transform: rank === 1 ? 'translateY(-20px) scale(1)' : 'translateY(-20px)' },
    delay: (3 - rank) * 1000 // show in reverse order
  });

  const medalUrls = {
    1: "https://img.icons8.com/?size=48&id=33852&format=png",
    2: "https://img.icons8.com/?size=80&id=YskxJ1NpCFQy&format=png",
    3: "https://img.icons8.com/?size=80&id=KL1txQ7JoYvd&format=png"
  };

  return (
    <animated.div style={props} className={`zr_animated-card rank-${rank}`}>
      <h2>{rank}. {name}</h2>
      <img src={medalUrls[rank]} alt={`Medaglia per il ${rank} posto`} className="zr_card-icon" />
    </animated.div>
  );
}

export default App;













