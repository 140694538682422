import React, { useState } from "react";
import { Card, Button } from '@headlessui/react';

const LineCard = ({ name, from, to, link }) => {
  const [clicked, setClicked] = useState(false);

  const handleButtonClick = () => {
    setClicked(true);
  };

  return (
    <div className="max-w-sm rounded-lg overflow-hidden shadow-md m-4 bg-white transition-transform transform hover:scale-105">
      <img className="w-full h-48 object-cover" src="https://pavia.autoguidovie.it/image/b1//190920_box%20extraurbano%20pavia%20per%20sito.jpg" alt="Autoguidovie" />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{name}</div>
        <p className="text-gray-700 text-base">
          Da: {from} <br />
          A: {to}
        </p>
        <Button
          as="a"
          href={link}
          target="_blank"
          onClick={handleButtonClick}
          className={`inline-flex items-center px-4 py-2 mt-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${clicked ? 'bg-blue-700' : ''}`}
        >
          Vedi orari
        </Button>
      </div>
    </div>
  );
};

const lines = [
  { name: 'Linea 87', from: 'Vigevano', to: 'Cassolnovo - Trecate - Novara Autostazione', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%2087_FER%20INV%20s.pdf' },
  { name: 'Linea 105', from: 'Garlasco S. Biagio', to: 'Zerbolò - Pavia Autostazione', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20105_FER%20INV%20s.pdf' },
  { name: 'Linea 106', from: 'Vigevano FS', to: 'Cilavegna - Mortara FS', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20106_FER%20INV%20s.pdf' },
  { name: 'Linea 107', from: 'Mortara FS', to: 'Garlasco/Vigevano', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20107_FER%20INV%20s.pdf' },
  { name: 'Linea 108', from: 'Dorno', to: 'Garlasco - Pavia Autostazione', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20108_FER%20INV%20s.pdf' },
  { name: 'Linea 110', from: 'Vigevano', to: 'Garlasco - Pavia Autostazione (via Gambolò/B.go S. Siro)', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20110_FER%20INV%20s.pdf' },
  { name: 'Linea 111', from: 'Mede FS', to: 'Pieve C. - Frascarolo - Valenza', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20111_FER%20INV%20s.pdf' },
  { name: 'Linea 112', from: 'Sannazzaro', to: 'Mezzana Bigli - Casale Monferrato', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20112_FER%20INV%20s.pdf' },
  { name: 'Linea 115', from: 'Pieve C.', to: 'Mede FS - Garlasco', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20115_FER%20INV%20s.pdf' },
  { name: 'Linea 125', from: 'Mortara FS', to: 'Mede FS - Pieve del Cairo - Voghera Autostazione', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20125_FER%20INV%20s.pdf' },
  { name: 'Linea 135', from: 'Vigevano', to: 'Gambolò Garbana - Mortara FS', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20135_FER%20INV%20s.pdf' },
  { name: 'Linea 145', from: 'Vigevano FS', to: 'Gambolò', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20145_FER%20INV%20s.pdf' },
  { name: 'Linea 153', from: 'Pieve del Cairo', to: 'Sannazzaro - Garlasco - Milano Famagosta M2', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20153_FER%20INV%20s.pdf' },
  { name: 'Linea 154', from: 'Voghera Autostazione', to: 'Pieve del Cairo - Sannazzaro - Garlasco - Vigevano', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20154_FER%20INV%20s.pdf' },
  { name: 'Linea 157', from: 'Mede FS', to: 'Zeme - Casale Monferrato', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20157_FER%20INV%20s.pdf' },
  { name: 'Linea 167', from: 'Mede FS', to: 'Gambolò - Vigevano/Valeggio - Garlasco', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20167_FER%20INV%20s.pdf' },
  { name: 'Linea 168', from: 'Dorno', to: 'Sannazzaro - Zinasco - Pavia Autostazione', link: 'https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20168_FER%20INV%20s.pdf' },
];

function OrarioAutoguidovieCard() {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-extrabold text-gray-900 mb-8 text-center">Linee Extraurbane Autoguidovie</h1>
      <p className="text-lg text-gray-700 mb-8 text-center">Qui trovi tutti gli orari e le informazioni per tutte le linee extraurbane Autoguidovie dell'area Lomellina.</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {lines.map((line, index) => (
          <LineCard key={index} name={line.name} from={line.from} to={line.to} link={line.link} />
        ))}
      </div>
      <div className="mt-12 text-center">
        <h2 className="text-xl font-bold text-gray-900 mb-4">Mappa dell'area lomellina</h2>
        <img src="https://moovitapp.com/index/public-transit-maps/image/?mapImage=v4%2Fmaps%2FItaly_Milano_e_Lombardia_Pavia_suburban_lines%2FItaly_Milano_e_Lombardia_Pavia_suburban_lines_3-desktop-.jpg" alt="Mappa dell'area lomellina" className="mx-auto" />
      </div>
    </div>
  );
}

export default OrarioAutoguidovieCard;

