// OrdiniEffettuati.js
import React, { useEffect, useState } from "react";
import { FaUser, FaInstagram, FaTshirt, FaCircle, FaCreditCard, FaIdBadge, FaEnvelope } from "react-icons/fa";
import { fetchOrdini } from '../logic/ordiniutils';
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

function OrdiniEffettuati() {
    const [ordini, setOrdini] = useState([]);

    useEffect(() => {
        fetchOrdini(setOrdini);
    }, []);

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Ordini effettuati</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {ordini.map((ordine, index) => (
                    <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                        <h2 className="text-xl font-semibold">Ordine #{index + 1}</h2>
                        <div className="mt-2">
                            <div className="flex items-center mb-2">
                                <FaUser className="mr-2" /> Nome: {ordine.nome}
                            </div>
                            <div className="flex items-center mb-2">
                                <FaInstagram className="mr-2" /> Nome Instagram: {ordine.nomeig}
                            </div>
                            <div className="flex items-center mb-2">
                                <FaTshirt className="mr-2" /> Taglia: {ordine.taglia}
                            </div>
                            <div className="flex items-center mb-2">
                                <FaCircle className="mr-2" color={ordine.colore} /> Colore: {ordine.colore}
                            </div>
                            <div className="flex items-center mb-2">
                                <FaCreditCard className="mr-2" /> Metodo di Pagamento: {ordine.pagamento}
                            </div>
                            <div className="flex items-center mb-2">
                                <FaIdBadge className="mr-2" /> Firebase UID: {ordine.uid}
                            </div>
                            <div className="flex items-center mb-2">
                                <FaEnvelope className="mr-2" /> Email: {ordine.email}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OrdiniEffettuati;

