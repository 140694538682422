// ordiniUtils.js
import { getDatabase, ref, get } from "firebase/database";

export const fetchOrdini = async (setOrdini) => {
    const db = getDatabase();
    const acquistiRef = ref(db, 'Acquisti');
    try {
        const snapshot = await get(acquistiRef);
        if (snapshot.exists()) {
            setOrdini(Object.values(snapshot.val()));
        } else {
            console.log("Nessun ordine trovato.");
        }
    } catch (error) {
        console.error('Errore nel recupero degli ordini:', error);
    }
};

