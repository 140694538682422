// useAcquistomaglietteLogic.js
// useAcquistomaglietteLogic.js
import React, { useState, useEffect } from "react";
import { ref, set } from "firebase/database";
import { auth, db } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";

const colorOptions = [
    { value: 'blu scuro', label: 'Blu Scuro', color: '#00008B' },
    { value: 'rosso', label: 'Rosso', color: '#FF0000' },
    { value: 'verde', label: 'Verde', color: '#008000' },
    { value: 'giallo', label: 'Giallo', color: '#FFFF00' },
    { value: 'arancione', label: 'Arancione', color: '#FFA500' }
];

const useAcquistomaglietteLogic = () => {
    const [formData, setFormData] = useState({
        nome: '',
        nomeig: '',
        taglia: 'M',
        quantita: '',
        colore: 'blu scuro',
        pagamento: 'online'
    });
    const [user, setUser] = useState(null);

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                window.location.href = '/login';
            }
        });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) {
            alert('Devi essere autenticato per completare l\'acquisto.');
            return;
        }

        const newPurchaseKey = Date.now(); // Usa timestamp come chiave unica

        try {
            await set(ref(db, `Acquisti/${newPurchaseKey}`), {
                ...formData,
                uid: user.uid,
                email: user.email
            });
            alert('Acquisto salvato con successo!');
        } catch (error) {
            console.error('Errore nel salvataggio:', error);
            alert('Errore nel salvataggio dell\'acquisto.');
        }
    };

    return {
        formData,
        handleChange,
        handleSubmit,
        colorOptions
    };
};

export default useAcquistomaglietteLogic;

