import React, { useEffect, useState } from 'react';
import { FaCalendarAlt, FaClock, FaMapMarkerAlt, FaInstagram } from 'react-icons/fa';
import '../../assets/css/animatedInformations.css'; // Assicurati che il percorso sia corretto

const Informations = () => {
  const [currentTime, setCurrentTime] = useState('');
  const [location, setLocation] = useState('');
  const [followers, setFollowers] = useState(null);

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      let timeString = now.toLocaleDateString('it-IT', options);
      timeString = timeString.charAt(0).toUpperCase() + timeString.slice(1);
      const timeParts = timeString.split(' alle ');
      const datePart = timeParts[0];
      const timePart = timeParts[1];
      setCurrentTime(
        <>
          <span className="Informations"><FaCalendarAlt /> {datePart}</span> <span className="Informations"><FaClock /> {timePart}</span>
        </>
      );
    };

    const fetchLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setLocation(`${data.city}, ${data.region}, ${data.country}`);
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };

    const fetchFollowers = async () => {
      try {
        const response = await fetch('http://localhost:5000/instagram/followers/ngl_cila');
        const data = await response.json();
        setFollowers(data.followers);
      } catch (error) {
        console.error('Error fetching Instagram followers:', error);
      }
    };

    updateTime();
    fetchLocation();
    fetchFollowers();
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="bg-black text-white p-4 font-sans" style={{ fontFamily: 'Inter, sans-serif' }}>
      <div className="container mx-auto flex justify-between items-center">
        <div>{currentTime}</div>
        <button
          className="Informations text-white flex items-center hover:text-teal-400 transition"
        >
          <FaMapMarkerAlt className="mr-2" /> {location}
        </button>
          
      </div>

      <style jsx>{`
        .Informations {
          display: flex;
          align-items: center;
          font-family: 'Inter', sans-serif;
        }

        .Informations svg {
          margin-right: 5px;
        }

        .Informations:hover {
          color: #00ff00; /* Colore verde per l'effetto hover */
        }

        .Informations:active {
          color: #00cc00; /* Colore verde scuro per l'effetto clic */
        }
      `}</style>
    </div>
  );
};

export default Informations;




