import React, { useState, useEffect } from "react";
import { FaInstagram, FaWhatsapp, FaNewspaper, FaStar } from "react-icons/fa";
import { ReactComponent as Anologo } from '../../assets/img/anonymouss.svg';
import Typical from 'react-typical';
import styles from '../../assets/css/IntestationHome.module.css';
import MessageComponent from './MessageComponent'; // Assicurati che il percorso sia corretto

function IntestationHome() {
  const [followers, setFollowers] = useState(null);

  useEffect(() => {
    const fetchFollowers = async () => {
      try {
        const response = await fetch('https://fetchinstagramfollower-production.up.railway.app/instagram/followers');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Followers data:', data);
        setFollowers(data.followers);
      } catch (error) {
        console.error('Error fetching Instagram followers:', error);
      }
    };

    fetchFollowers();
  }, []);

  return (
    <div className={styles["root-hm"]}>
      <div className={styles["background-hm"]}></div>
      <div className={styles["overlay-hm"]}></div>
      <div className={styles["content-hm"]}>
        <h1 className={styles["title-hm"]}>
          <span className={styles["highlight-hm"]}>NGL CILA</span>, IL FUTURO <br /> DEL DIVERTIMENTO <br /> È <span className={styles["highlight-hm"]}>QUA!</span>
        </h1>
        <p className={styles["typicalText-hm"]}>
          <Typical
            steps={['Esplora i Gossip di Cila', 2000, 'Guarda le ultime notizie', 2000, 'Visita il nostro sito web per attività interattive', 2000, 'Partecipa ai nostri quiz', 2000, 'Vota ai nostri sondaggi', 2000, 'Scrivi un Ngl', 2000, 'Leggi la scienza semplicemente con NGLPop!', 2000, 'Guarda le votazioni', 2000, 'Interagisci con noi', 2000, 'Partecipa ai nostri eventi', 2000]}
            loop={Infinity}
            wrapper="span"
          />
        </p>
        {followers !== null && (
          <div className={styles["followersContainer-hm"]}>
            <FaInstagram className={styles["followersIcon-hm"]} />
            <span className={styles["followersText-hm"]}>{followers} followers su Instagram </span>
          </div>
        )}
        <div className={styles["links-hm"]}>
          <a href="https://www.instagram.com/ngl_cilaa" target="_blank" rel="noopener noreferrer" className={`${styles["link-hm"]} ${styles["instagram-hm"]}`}>
            <FaInstagram />  Instagram
          </a>
          <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer" className={`${styles["link-hm"]} ${styles["whatsapp-hm"]}`}>
            <FaWhatsapp />  WhatsApp
          </a>
          <a href="/news" target="_blank" rel="noopener noreferrer" className={`${styles["link-hm"]} ${styles["news-hm"]}`}>
            <FaNewspaper />  Notizie
          </a>
          <a href="/altro" target="_blank" rel="noopener noreferrer" className={`${styles["link-hm"]} ${styles["altro-hm"]}`}>
            <FaStar />  Altro
          </a>
          <a href="https://anonymouss.me/ngl_cila" target="_blank" rel="noopener noreferrer" className={`${styles["link-hm"]} ${styles["anonymous-hm"]}`}>
            <Anologo style={{ width: '24px', height: '24px', fill: 'white' }} /> Scrivi una domanda anonima
          </a>
        </div>
      </div>
    </div>
  );
}

export default IntestationHome;
















