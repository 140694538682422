import React, { useState } from 'react';
import { useCombobox } from 'downshift';
import stations from '../../assets/olther/stations.json'; // Importa il file JSON delle stazioni

function InserisciCodiceTreno() {
  const [codiceTreno, setCodiceTreno] = useState('');
  const [selectedStazione, setSelectedStazione] = useState('');
  const [loading, setLoading] = useState(false);
  const [trainDetails, setTrainDetails] = useState(null); // Stato per memorizzare i dettagli del treno
  const [stationsList, setStationsList] = useState(stations.slice(0, 20));
  const [moreStations, setMoreStations] = useState(stations.length > 20);
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedStazione) {
      alert("Per favore, seleziona una stazione di partenza.");
      return;
    }
    setLoading(true);
    try {
      const selectedStation = stations.find(station => station.long_name === selectedStazione);
      const stazioneCode = selectedStation.code; // Utilizza 'code' come ID della stazione
      const date = new Date().getTime(); // Utilizza la data corrente
      const response = await fetch(`https://trenitaliapijs-production.up.railway.app/train/details/${stazioneCode}/${codiceTreno}/${date}`);
      const data = await response.json();
      setTrainDetails(data); // Memorizza i dettagli del treno
      console.log(data); // Visualizza i dati nel console log
    } catch (error) {
      console.error("Errore nel recupero dei dettagli del treno:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTrainStatus = (tipoTreno, provvedimento) => {
    if (tipoTreno === 'PG' && provvedimento === 0) {
      return 'Treno regolare';
    }
    if (tipoTreno === 'ST' && provvedimento === 1) {
      return 'Treno soppresso';
    }
    if ((tipoTreno === 'PP' || tipoTreno === 'SI' || tipoTreno === 'SF') && (provvedimento === 0 || provvedimento === 2)) {
      return 'Treno parzialmente soppresso';
    }
    if (tipoTreno === 'DV' && provvedimento === 3) {
      return 'Treno deviato';
    }
    return 'Stato del treno non specificato';
  };

  const getTrainOperatorByCodiceCliente = (codiceCliente) => {
    switch (codiceCliente) {
      case 1:
        return 'FRECCIAROSSA/TRENITALIA AV';
      case 2:
        return 'TRENITALIA';
      case 4:
        return 'TRENITALIA INTERCITY';
      case 18:
        return 'TRENITALIA TPER';
      case 63:
        return 'TRENORD';
      case 64:
        return 'OBB';
      default:
        return 'Operatore sconosciuto';
    }
  };

  const loadMoreStations = () => {
    const newLength = stationsList.length + 20;
    setStationsList(stations.slice(0, newLength));
    setMoreStations(newLength < stations.length);
  };

  const filteredStations = (inputValue) => {
    return stations.filter(station =>
      station.long_name.toLowerCase().includes(inputValue.toLowerCase())
    ).slice(0, 20);
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getLabelProps,
    getToggleButtonProps,
    getItemProps,
    highlightedIndex,
    selectedItem,
  } = useCombobox({
    items: filteredStations(inputValue),
    onInputValueChange: ({ inputValue }) => {
      setInputValue(inputValue);
      setStationsList(filteredStations(inputValue));
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        if (selectedItem.code === "load_more") {
          loadMoreStations();
        } else {
          setSelectedStazione(selectedItem.long_name);
        }
      }
    },
    itemToString: (item) => (item ? item.long_name : ''),
  });

  const defaultOptions = stationsList.map(station => ({
    long_name: station.long_name,
    code: station.code
  }));

  if (moreStations) {
    defaultOptions.push({ long_name: "Mostra altre stazioni...", code: "load_more" });
  }

  return (
    <div className="container mx-auto mt-5">
      <h2 className="text-2xl font-bold mb-5">Inserisci il Codice del Treno e la Stazione di Provenienza</h2>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="codiceTreno">
            Codice del Treno
          </label>
          <input
            type="text"
            id="codiceTreno"
            placeholder="Inserisci il codice del treno"
            value={codiceTreno}
            onChange={(e) => setCodiceTreno(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="stazioneProvenienza">
            Stazione di Provenienza
          </label>
          <div {...getToggleButtonProps()}>
            <input
              {...getInputProps()}
              className="zcns-select-input"
              placeholder="Seleziona una stazione..."
            />
          </div>
          <ul {...getMenuProps()} className="zcns-menu">
            {isOpen && 
              defaultOptions.map((item, index) => (
                <li
                  key={index}
                  {...getItemProps({ item, index })}
                  className={`zcns-menu-item ${highlightedIndex === index ? 'zcns-highlighted-item' : ''}`}
                >
                  {item.long_name}
                </li>
              ))
            }
          </ul>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={loading}
          >
            {loading ? 'Caricamento...' : 'Conferma'}
          </button>
        </div>
      </form>
      {loading ? (
        <div className="flex flex-col items-center mt-5">
          <svg className="animate-spin h-8 w-8 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
          </svg>
          <p>Attendere Prego...</p>
        </div>
      ) : (
        trainDetails && (
          <div className="mt-5 p-5 bg-white shadow-md rounded">
            <h3 className="text-xl font-bold mb-4">Dettagli del Treno</h3>
            <p><strong>Numero Treno:</strong> {trainDetails.numeroTreno}</p>
            <p><strong>Categoria:</strong> {trainDetails.categoria}</p>
            <p><strong>Partenza (Origine):</strong> {trainDetails.origine}</p>
            <p><strong>Destinazione:</strong> {trainDetails.destinazione}</p>
            <p><strong>Ritardo:</strong> {trainDetails.ritardo} minuti</p>
            <p><strong>Stato del Treno:</strong> {getTrainStatus(trainDetails.tipoTreno, trainDetails.provvedimento)}</p>
            <p><strong>Operatore:</strong> {getTrainOperatorByCodiceCliente(trainDetails.codiceCliente)}</p>
            <p><strong>Binario Programmato di Partenza:</strong> {trainDetails.binarioProgrammatoPartenzaDescrizione}</p>
            <p><strong>Binario Effettivo di Partenza:</strong> {trainDetails.binarioEffettivoPartenzaDescrizione}</p>
            <p><strong>Binario Effettivo di Arrivo:</strong> {trainDetails.binarioEffettivoArrivoDescrizione}</p>
            <p><strong>Orario di Partenza:</strong> {trainDetails.compOrarioPartenza}</p>
            <p><strong>Orario di Arrivo:</strong> {trainDetails.compOrarioArrivo}</p>
            <p><strong>Durata del Viaggio:</strong> {trainDetails.compDurata}</p>
            <p><strong>Stazioni di passaggio:</strong></p>
            <ul className="list-disc list-inside">
              {trainDetails.fermate && trainDetails.fermate.map((stazione, index) => (
                <li key={index}>{stazione.stazione}</li>
              ))}
            </ul>
            <p><strong>Ultimo Rilevamento:</strong> {trainDetails.stazioneUltimoRilevamento}</p>
          </div>
        )
      )}
    </div>
  );
}

export default InserisciCodiceTreno;

            

