import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
import { FaUser, FaBoxOpen, FaShoppingCart, FaServicestack, FaMedal, FaEllipsisH } from 'react-icons/fa';

const AccountDashboardLayout = () => {
  const [username, setUsername] = useState('');
  const [uid, setUid] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const db = getDatabase();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
        const userRef = ref(db, `users/${user.uid}`);
        get(userRef).then((snapshot) => {
          if (snapshot.exists()) {
            setUsername(snapshot.val().username);
          }
        }).catch((error) => {
          console.error("Errore durante il recupero del username: ", error);
        });
      } else {
        navigate('/login');
      }
    });
  }, [navigate]);

  const sections = [
    { name: 'Account', icon: <FaUser size={30} />, link: '/account' },
    { name: 'Ordini', icon: <FaBoxOpen size={30} />, link: '/orders' },
    { name: 'Vendite', icon: <FaShoppingCart size={30} />, link: '/sales' },
    { name: 'Servizi Abilitati', icon: <FaServicestack size={30} />, link: '/services' },
    { name: 'Punteggi', icon: <FaMedal size={30} />, link: '/scores' },
    { name: 'Altro', icon: <FaEllipsisH size={30} />, link: '/other' }
  ];

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error("Errore durante il logout: ", error);
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-5">
      <h1 className="text-4xl font-bold mb-8 text-gray-800">
        Benvenuto {username ? username : ''}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6 w-full max-w-5xl">
        {sections.map((section) => (
          <div key={section.name} className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center justify-center transition-transform transform hover:scale-105">
            <div className="text-blue-500 mb-4">{section.icon}</div>
            <h2 className="text-xl font-semibold mb-2">{section.name}</h2>
            <a href={section.link} className="text-blue-500 hover:text-blue-700 transition-colors">Vai alla sezione</a>
          </div>
        ))}
      </div>
      <button
        onClick={handleLogout}
        className="mt-8 bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        Esci dall'Account
      </button>
    </div>
  );
};

export default AccountDashboardLayout;

