import React from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import NGLPopLayout from "../components/layout/NGLPopLayout";


function NGLPop() {
    return (
        <div>
           <Navbar />
           <NGLPopLayout />
           <Footer /> 
        </div>
    );
}

export default NGLPop;