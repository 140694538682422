import React from "react";
import Popcard from "./Popcard";
import UserAccessData from "../logic/userAccessData";

function NGLPopLayout() {
    return (
        <div className="bg-white min-h-screen flex flex-col items-center px-4 md:px-8 lg:px-16 font-sans">
            <UserAccessData />
            <header className="text-center my-8">
                <h1 className="text-4xl md:text-5xl lg:text-6xl text-black mb-4" style={{ fontFamily: 'Inter, sans-serif' }}>
                    NGLPop
                </h1>
                <p className="text-lg md:text-xl lg:text-2xl text-gray-700" style={{ fontFamily: 'Inter, sans-serif' }}>
                    Benvenuti nel nostro blog scientifico, esplorate gli articoli più recenti e scoprite nuove scoperte.
                </p>
            </header>
            <main className="flex flex-col items-center w-full">
                
                <section id="articles" className="w-full">
                    <Popcard />
                </section>
            </main>
           
        </div>
    );
}

export default NGLPopLayout;


