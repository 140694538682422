import React from 'react';

// Animazione per Evento 1
const AnimatedEvent1 = () => {
  return (
    <div className="flex flex-col items-center p-4 my-4 bg-white shadow-md rounded-lg">
      <div className="animate-bounce mb-4">
        <img src="https://via.placeholder.com/150" alt="Evento 1" className="w-32 h-32 rounded-full shadow-lg" />
      </div>
      <h2 className="text-xl font-bold mt-4">Evento 1</h2>
      <p className="mt-2">Descrizione dell'evento numero 1 che può essere aggiornata con informazioni reali.</p>
    </div>
  );
};

// Animazione per Evento 2
const AnimatedEvent2 = () => {
  return (
    <div className="flex flex-col items-center p-4 my-4 bg-white shadow-md rounded-lg">
      <div className="animate-spin mb-4">
        <img src="https://via.placeholder.com/150" alt="Evento 2" className="w-32 h-32 rounded-full shadow-lg" />
      </div>
      <h2 className="text-xl font-bold mt-4">Evento 2</h2>
      <p className="mt-2">Descrizione dell'evento numero 2 che può essere aggiornata con informazioni reali.</p>
    </div>
  );
};

// Componente per un singolo evento
const EventCard = ({ title, description, animationClass }) => {
  return (
    <div className="flex flex-col items-center p-4 my-4 bg-white shadow-md rounded-lg">
      <div className={`${animationClass} mb-4`}>
        <img src="https://via.placeholder.com/150" alt={title} className="w-32 h-32 rounded-full shadow-lg" />
      </div>
      <h2 className="text-xl font-bold mt-4">{title}</h2>
      <p className="mt-2">{description}</p>
    </div>
  );
};

// Pagina eventi
const EventsPage = () => {
  const events = [
    { title: 'Evento 1', description: "Descrizione dell'evento numero 1.", animationClass: 'animate-bounce' },
    { title: 'Evento 2', description: "Descrizione dell'evento numero 2.", animationClass: 'animate-spin' },
    // Aggiungi qui altri eventi
  ];

  return (
    <div className="flex flex-col items-center text-center p-4 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold font-inter mb-8">Eventi in Programma</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {events.map((event, index) => (
          <EventCard
            key={index}
            title={event.title}
            description={event.description}
            animationClass={event.animationClass}
          />
        ))}
      </div>
    </div>
  );
};

export default EventsPage;

