import React, {useEffect} from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import AltroSection from "../components/layout/AltroSection";
import UserAccessData from "../components/logic/userAccessData";

function Altro() {
    useEffect(() => {
            document.title = 'Altro'; // Imposta il titolo della pagina
          }, []);
    return (
        <div>
            <Navbar/>
            <UserAccessData />
            <AltroSection />
            <Footer />
        </div>
    );
}

export default Altro;