import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import Rating from 'react-rating';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

const PARSE_APPLICATION_ID = 'h6n3hXeAyPq4Q9VYaiI1n5FnRIxoeCdlrynUbAMA';
const PARSE_JAVASCRIPT_KEY = 'Ts74nlTiApt0TLezItum716SuEnWEIsujWFpjsct';
const PARSE_HOST_URL = 'https://parseapi.back4app.com';

Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
Parse.serverURL = PARSE_HOST_URL;

function ReviewCard() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const Review = Parse.Object.extend('Review');
      const query = new Parse.Query(Review);
      try {
        const results = await query.find();
        setReviews(results);
      } catch (error) {
        console.error('Error while fetching reviews: ', error);
      }
    };

    fetchReviews();
  }, []);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-8">
      <div className="w-full max-w-2xl h-full max-h-screen overflow-y-auto p-8 bg-blue-900 text-white rounded-xl shadow-lg border-b-4 border-gray-300">
        <h2 className="text-center text-3xl font-bold mb-6">Recensioni</h2>
        {reviews.map((review) => (
          <div key={review.id} className="mb-4 pb-4 border-b border-gray-300">
            <div className="mb-2">
              <Rating
                readonly
                initialRating={review.get('rating')}
                emptySymbol={<FaRegStar color="#ffd700" size={24} />}
                fullSymbol={<FaStar color="#ffd700" size={24} />}
                halfSymbol={<FaStarHalfAlt color="#ffd700" size={24} />}
              />
            </div>
            <p>{review.get('reviewText')}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ReviewCard;
