import React, { useEffect } from 'react';
import '../assets/css/animations.css';
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import Informations from '../components/layout/Informations';
import IntestationHome from '../components/layout/IntestationHome';
import UserAccessData from '../components/logic/userAccessData';


function Home() {
  useEffect(() => {
    document.title = 'Home'; // Imposta il titolo della pagina
  }, []);

  return (
    <div>
      <Navbar />
      <Informations />
      <UserAccessData />
      <IntestationHome />
      <Footer />
    </div>
  );
}

export default Home;

