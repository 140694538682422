import React from "react";
import Navbar from "../components/layout/Navbar";
import ClassificaBellezzaPageLayout from "../components/layout/ClassificaBellezzaLayout";
import Footer from "../components/layout/Footer";

function ClassificaBellezzaPage() {
    return (
        <>
            <Navbar />
            <ClassificaBellezzaPageLayout />
            <Footer />
        </>
    );
}

export default ClassificaBellezzaPage;