import React, { useState, useEffect } from 'react';
import { fetchAccessData, fetchBannedIPs, handleConfirmBan, handleUnban } from '../logic/accessdatautils';

function AccessDataTable() {
  const [accessData, setAccessData] = useState([]);
  const [bannedIPs, setBannedIPs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentIP, setCurrentIP] = useState('');
  const [banReason, setBanReason] = useState('');
  const [banDuration, setBanDuration] = useState('permanent');
  const [banExpiryDate, setBanExpiryDate] = useState(null);
  const [manualIP, setManualIP] = useState('');

  useEffect(() => {
    fetchAccessData(setAccessData);
    fetchBannedIPs(setBannedIPs);
  }, []);

  const handleBan = (ip) => {
    setCurrentIP(ip);
    setShowModal(true);
  };

  const handleManualBan = () => {
    handleBan(manualIP);
    setManualIP('');
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-collapse border border-black">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Orario di Accesso</th>
              <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Indirizzo IP</th>
              <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Coordinate</th>
              <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Nome Utente</th>
              <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">UID</th>
              <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Azione</th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {accessData
              .filter((data) => !bannedIPs.includes(data.ipAddress))
              .map((data) => (
                <tr key={data.id}>
                  <td className="py-3 px-4 border border-black">{data.dateTime}</td>
                  <td className="py-3 px-4 border border-black">{data.ipAddress}</td>
                  <td className="py-3 px-4 border border-black">
                    {data.location && data.location.latitude && data.location.longitude
                      ? `${data.location.latitude}, ${data.location.longitude}`
                      : 'Non concessa'}
                  </td>
                  <td className="py-3 px-4 border border-black">{data.username}</td>
                  <td className="py-3 px-4 border border-black">{data.uid}</td>
                  <td className="py-3 px-4 border border-black">
                    <button
                      onClick={() => handleBan(data.ipAddress)}
                      className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Ban
                    </button>
                  </td>
                </tr>
              ))}
            {bannedIPs.map((ip, index) => (
              <tr key={index}>
                <td colSpan="5" className="py-3 px-4 border border-black">{ip}</td>
                <td className="py-3 px-4 border border-black">
                  <button
                    onClick={() => handleUnban(ip, setBannedIPs, bannedIPs)}
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Unban
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-3 mb-3 flex">
        <input
          type="text"
          placeholder="Inserisci IP"
          value={manualIP}
          onChange={(e) => setManualIP(e.target.value)}
          className="border rounded-md py-2 px-4 mr-2"
        />
        <button onClick={handleManualBan} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
          Ban IP
        </button>
      </div>

      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Motivazione del Ban
              </h3>
              <div className="mt-2">
                <form>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Motivazione</label>
                    <input
                      type="text"
                      value={banReason}
                      onChange={(e) => setBanReason(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                      placeholder="Inserisci la motivazione del ban"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Durata del Ban</label>
                    <select
                      value={banDuration}
                      onChange={(e) => setBanDuration(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                    >
                      <option value="permanent">Permanente</option>
                      <option value="temporary">Temporaneo</option>
                    </select>
                  </div>
                  {banDuration === 'temporary' && (
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Data di Scadenza</label>
                      <input
                        type="date"
                        value={banExpiryDate}
                        onChange={(e) => setBanExpiryDate(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                      />
                    </div>
                  )}
                </form>
              </div>
              <div className="mt-4">
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-white rounded px-4 py-2 mr-2"
                  onClick={() => setShowModal(false)}
                >
                  Annulla
                </button>
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white rounded px-4 py-2"
                  onClick={() => handleConfirmBan(currentIP, banReason, banDuration, banExpiryDate, setBannedIPs, bannedIPs, setShowModal, setBanReason, setBanDuration, setBanExpiryDate)}
                >
                  Conferma Ban
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AccessDataTable;



