import React from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import DashboardAdminLayout from "../components/layout/DashboardAdminLayout";

const DashboardAdmin = () => {
    return <div>
        <Navbar />
        <DashboardAdminLayout />
        <Footer />
    </div>;
};

export default DashboardAdmin;