import React from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import PrivacyPolicy from "../components/layout/PrivacyPolicy";


function Policy() {
    return (
        <div>
            <Navbar />
            <PrivacyPolicy />
            <Footer />
        </div>
    );
}

export default Policy;