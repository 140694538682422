import React, { useState } from "react";
import { FaPhone, FaEnvelope, FaInstagram, FaWhatsapp, FaTiktok } from 'react-icons/fa';
import NglLogo from '../../assets/img/ngllogo.jpg';

function Footer() {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="bg-black text-white py-8 font-sans" style={{ fontFamily: 'Inter, sans-serif' }}>
      <div className="container mx-auto px-4">
        <div className="flex justify-center mb-8">
          <h1 className="text-center text-lg md:text-xl">
            Copyright © 2025/NGL Cila, tutti i diritti riservati
          </h1>
        </div>
        <div className="flex flex-col md:flex-row justify-between px-4">
          <div className="md:w-1/2 mb-8">
            <h2 className="text-xl mb-4">Cos'è NGLCila?</h2>
            <p className={`transition-all ${showMore ? 'block' : 'line-clamp-3'}`}>
              NGLCila è un progetto di giornalismo e gossip dedicato ai ragazzi di Cilavegna e dintorni. La nostra missione è fornire notizie fresche, divertenti e coinvolgenti che riguardano la comunità locale. Che si tratti di eventi, storie di persone, o semplici curiosità, NGLCila è qui per tenervi informati e intrattenuti.
            </p>
            <button onClick={() => setShowMore(!showMore)} className="text-teal-400 mt-2 hover:text-teal-600 transition">
              {showMore ? "Mostra meno" : "Mostra tutto"}
            </button>
          </div>
          <div className="md:w-1/4">
            <ul className="list-none">
              <li><a href="/chisiamo" className="text-white hover:text-teal-400 transition">Chi siamo</a></li>
              <li><a href="/gdpr" className="text-white hover:text-teal-400 transition">Termini e uso dei dati personali (GDPR)</a></li>
              <li><a href="/contatti" className="text-white hover:text-teal-400 transition">Contatti</a></li>
            </ul>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <img src={NglLogo} alt="NGLCila Logo" className="w-36 h-auto" />
        </div>
        <div className="flex justify-center mt-8 space-x-8">
          <a href="https://instagram.com/ngl_cila" className="text-white hover:text-teal-400 transition">
            <FaPhone className="inline mr-2" />Scrivici su Instagram in DM!
          </a>
          <a href="mailto:info@nglcila.com" className="text-white hover:text-teal-400 transition">
            <FaEnvelope className="inline mr-2" />info@nglcila.com
          </a>
        </div>
        <div className="flex justify-end mt-8 space-x-4">
          <a href="https://instagram.com/ngl_cila" className="bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 rounded-full p-3 hover:scale-105 transition-transform">
            <FaInstagram className="text-white" />
          </a>
          <a href="https://whatsapp.com/channel/0029VajF80s6BIEcButOf50E" className="bg-green-500 rounded-full p-3 hover:scale-105 transition-transform">
            <FaWhatsapp className="text-white" />
          </a>
          <a href="https://chat.whatsapp.com/BvxvOf2O8cWAJsjlt1Avcm" className="bg-green-500 rounded-full p-3 hover:scale-105 transition-transform">
            <FaWhatsapp className="text-white" />
          </a>
          <a href="https://www.tiktok.com/@ngl_cila?_t=8pip3VNfjG4&_r=1" className="bg-black rounded-full p-3 hover:scale-105 transition-transform">
            <FaTiktok className="text-white" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;

