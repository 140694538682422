import React, { useState } from "react";
import { FaImage, FaHeading, FaAlignLeft } from 'react-icons/fa';

const NewsForm = ({ saveNews }) => {
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [img, setImg] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        saveNews({ title, text, img });
        setTitle("");
        setText("");
        setImg("");
    };

    return (
        <div className="container mx-auto p-4">
            <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-md">
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                        <FaHeading className="inline-block mr-2" /> Titolo
                    </label>
                    <input
                        type="text"
                        placeholder="Inserisci il titolo"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                        <FaAlignLeft className="inline-block mr-2" /> Descrizione
                    </label>
                    <textarea
                        rows={3}
                        placeholder="Inserisci la descrizione"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Immagine (URL)</label>
                    <input
                        type="text"
                        placeholder="Inserisci l'URL dell'immagine"
                        value={img}
                        onChange={(e) => setImg(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        required
                    />
                </div>

                <button
                    type="submit"
                    className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                    Aggiungi Notizia
                </button>
            </form>
        </div>
    );
};

export default NewsForm;
