import React, { useState, useEffect } from 'react';

const NotizieFerrovie = () => {
  const [notizie, setNotizie] = useState([]);
  const [loading, setLoading] = useState(true); // Stato per il caricamento

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const [tickerResponse, rssResponse] = await Promise.all([
          fetch('https://trenitaliapijs-production.up.railway.app/news/ticker'),
          fetch('https://trenitaliapijs-production.up.railway.app/news/rss')
        ]);
        const tickerData = await tickerResponse.json();
        const rssData = await rssResponse.json();
        setNotizie([...tickerData, ...rssData]);
      } catch (error) {
        console.error("Errore nel fetch delle notizie:", error);
      } finally {
        setLoading(false); // Imposta il caricamento a false una volta ottenuti i dati
      }
    };
    fetchNews();
  }, []);

  return (
    <div className="overflow-hidden relative bg-gray-100 py-4">
      {loading ? (
        <div className="flex flex-col items-center justify-center h-full">
          <svg className="animate-spin h-8 w-8 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
          </svg>
          <p>Attendere Prego...</p>
        </div>
      ) : (
        <div className="flex flex-col items-center">
          {notizie.map((notizia, index) => (
            <div 
              key={index} 
              className="bg-blue-100 mb-4 p-4 w-full min-w-fit shadow-md rounded-md"
            >
              <h5 className="font-bold">{notizia.titolo}</h5>
              <p className="text-gray-700">{new Date(notizia.data).toLocaleString()}</p>
              <div dangerouslySetInnerHTML={{ __html: notizia.testo }}></div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotizieFerrovie;
