import React from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import EventsPage from "../components/layout/EventiLayout";


function Eventi() {
    return (
        <div>
            <Navbar />
            <EventsPage />
            <Footer />
        </div>
    );
}

export default Eventi;
