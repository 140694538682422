import React from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import MaglietteLayout from "../components/layout/AcquistoMagliette";
import UserAccessData from "../components/logic/userAccessData";

function AcquistoMagliette() {
    return (
        <div>
            <Navbar />
            <UserAccessData />
            <MaglietteLayout />
            <Footer />
        </div>
    );
}

export default AcquistoMagliette;
