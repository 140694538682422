import React from "react";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import LoginPageLayout from "../components/layout/LoginLayout";

const LoginPage = () => {
    return (
        <div>
            <Navbar />
            <LoginPageLayout />
            <Footer />
        </div>
    );
}

export default LoginPage;