import React from 'react';

const TableTreni = ({ treni }) => {
  return (
    <table className="min-w-full bg-white border-collapse border border-black mt-4">
      <thead className="bg-gray-800 text-white">
        <tr>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Compagnia</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Numero Treno</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Tipologia</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Destinazione</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Ora Partenza</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Ritardo</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Stato Treno</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Binario Programmato</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Binario Reale</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Categoria</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Descrizione Categoria</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Binario Effettivo Partenza</th>
        </tr>
      </thead>
      <tbody className="text-gray-700">
        {treni.map((treno, index) => (
          <tr key={index}>
            <td className="py-3 px-4 border border-black">
              <img src={treno.iconaTreno} alt="Logo Compagnia Ferroviaria" className="w-16 h-auto object-contain" />
            </td>
            <td className="py-3 px-4 border border-black">{treno.numeroTreno}</td>
            <td className="py-3 px-4 border border-black">
              <img src={treno.tipologiaTrenoIcona} alt="Icona Tipologia Treno" className="w-8 h-auto object-contain inline-block mr-2" />
              
            </td>
            
            <td className="py-3 px-4 border border-black">{treno.destinazione}</td>
            <td className="py-3 px-4 border border-black">{new Date(treno.orarioPartenza).toLocaleTimeString()}</td>
            <td className="py-3 px-4 border border-black">{treno.ritardo} minuti</td>
            <td className="py-3 px-4 border border-black">{treno.statoTreno ? treno.statoTreno : "Informazioni non disponibili"}</td>
            <td className="py-3 px-4 border border-black">{treno.binarioProgrammato}</td>
            <td className="py-3 px-4 border border-black">{treno.binarioReale}</td>
            <td className="py-3 px-4 border border-black">{treno.categoria}</td>
            <td className="py-3 px-4 border border-black">{treno.categoriaDescrizione}</td>
            <td className="py-3 px-4 border border-black">{treno.binarioEffettivoPartenzaDescrizione}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableTreni;


